import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import goToCDN from "../methods/goToCDN";

// imgSize is the size of the downloaded image
// should be one of "small", "medium", "large"
const AccessibleImage = ({ img, className, imgSize, hoverable }) => {
  const { src, attribution, alt } = img || {};

  if (src) {
    return (
      <div className={classNames(className, "overflow-hidden shadow-sm rounded-lg")}>
        <img
          src={goToCDN(src, imgSize, true)}
          alt={alt || ""}
          className={`${className} ${
            hoverable ? "hover:scale-110 transition duration-500" : ""
          }`}
        />
      </div>
    );
  } else {
    return (
      <div
        className={classNames(className, `bg-primary bg-opacity-60 flex items-center justify-center overflow-hidden`)}
      >
        <div className="bg-accent h-3/4 w-3/4 rounded-xl bg-opacity-80 flex items-center rotate-12 justify-center">
          <div className="bg-primary h-2/3 w-2/3 bg-opacity-60 rotate-45 rounded-lg flex items-center justify-center">
            <div className="bg-accent h-1/2 w-1/2 bg-opacity-80 -rotate-12 rounded-md" />
          </div>
        </div>
      </div>
    );
  }
};

AccessibleImage.propTypes = {
  className: PropTypes.string,
  obj: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    attribution: PropTypes.object,
  }),
  imgSize: PropTypes.string,
  hoverable: PropTypes.bool,
};

AccessibleImage.defaultProps = {
  className: "",
  obj: {
    src: null,
    alt: null,
    attribution: null,
  },
  imgSize: "large",
  hoverable: false,
};

export default AccessibleImage;
