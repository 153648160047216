import dayjs from "dayjs";
import { rrulestr } from "rrule";

const transformDate = (date) => {
  if (typeof date.toDate === "function") {
    return date.toDate();
  } else if (typeof date === "string") {
    return new Date(date);
  } else {
    return date;
  }
};

const setTimesFromStart = (day, event) => {
  if (event.start) {
    const start = event.start?.toDate
      ? event.start.toDate()
      : new Date(event.start);
    const hours = start.getHours();
    const minutes = start.getMinutes();
    const seconds = start.getSeconds();
    day.setHours(hours, minutes, seconds || 0);
  }
  return day;
};

export const datesFromRecurring = (event, range) => {
  if (event.recurrence?.RRULE) {
    let rule;
    try {
      let rangeEnd = transformDate(range.end);
      rule = rrulestr(event.recurrence.RRULE);

      if (event.concluded) {
        // Have event concluded be end of day so we don't cut any dates off.
        const transformedDate = dayjs(transformDate(event.concluded)).endOf("day");
        if (transformedDate.isBefore(rangeEnd)) {
          rangeEnd = transformedDate.toDate();
        }
      }

      const returnString = rule.between(
        range.start,
        rangeEnd
      );
      return returnString.map((item) => setTimesFromStart(item, event));
    } catch (err) {
      console.warn(err);
      return [];
    }
  } else if (event?.recurrence?.dates) {
    const createDate = (date) => {
      // date.parse() doesn't work in Safari
      const dateArray = date.split("-");
      const day = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
      // set time from event start time
      if (event.start) {
        const start = event.start?.toDate
          ? event.start.toDate()
          : new Date(event.start);
        const hours = start.getHours();
        const minutes = start.getMinutes();
        const seconds = start.getSeconds();
        day.setHours(hours, minutes, seconds || 0);
      }
      return day;
    };

    return event.recurrence.dates
      .filter((date) => {
        const dateMoment = createDate(date);
        if (range) {
          return dateMoment >= range.start && dateMoment <= range.end;
        } else {
          return true;
        }
      })
      .map((date) => createDate(date));
  } else {
    return [];
  }
};

export const getNextRecurrTime = (event) => {
  const dates = datesFromRecurring(event, {
    start: new Date(),
    end: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  });
  if (dates.length > 0) {
    return dates[0].getTime() / 1000;
  }
  return null;
};

