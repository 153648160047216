import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  BuildingLibraryIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import dayjs from "dayjs";

import { MapPinIcon, UserIcon } from "@heroicons/react/24/solid";
import recurrenceString from "../lib/utilities/recurrenceString";
import { moneyFormat } from "./Booking";
import AccessibleImage from "./AccessibleImage";
import { ageConditionToText, eventAgeRestriction } from "../methods/eventUtil";

const gradients = [
  "bg-gradient-to-br from-yellow-500 to-indigo-400",
  "bg-gradient-to-br from-yellow-500 to-green-400",
  "bg-gradient-to-br from-red-500 to-purple-400",
];

const wrapDate = (date) => {
  if (date?.toDate?.()) {
    return date.toDate();
  }
  return date;
};

const timeString = (event) => {
  if (Array.isArray(event?.bookingDetails?.timeSlots) && event?.bookingDetails?.showTimeSlots) {
    return "multiple time slots";
  } else {
    return `${dayjs(wrapDate(event?.start)).format("H:mm")}-${dayjs(
      wrapDate(event?.end)
    ).format("H:mm")}`;
  }
};

const EventCard = ({
  eventData,
  i,
  hideOrg,
  flatBottom,
  hoverClass,
  small,
  embed,
  textClassName,
  handleClick,
  noLink,
  showOrgChip,
  isProgramme,
  rules,
}) => {
  const isFree =
    eventData?.bookingDetails?.free ||
    !eventData?.bookingDetails?.price ||
    (eventData?.bookingDetails?.price !== undefined &&
      eventData?.bookingDetails?.price === 0);

  const ageRestriction = eventAgeRestriction(rules);
  const hasAgeRestriction = ageRestriction && ageRestriction.length > 0;
  const hasYears = eventData?.name.text.match(/\(.*years\)/);

  const content = (
    <div className="flex flex-col h-full">
      <div className="relative">
        <div className="absolute top-2 left-2 flex flex-row space-x-1 z-20">
          <p
            className={classNames(
              {
                "text-sm": small,
                uppercase: isFree,
              },
              `font-medium px-2 py-0.5 text-white bg-primary rounded-md`
            )}
          >
            {isFree || isProgramme
              ? "Free"
              : `${
                  eventData?.bookingDetails?.complicated ? "Up to " : ""
                }${moneyFormat(eventData?.bookingDetails?.price)}`}
            <span className="font-light">
              {!eventData?.bookingDetails?.free &&
                eventData?.bookingDetails?.price &&
                (eventData?.bookingDetails?.unit === "group"
                  ? "/group"
                  : "/person")}
            </span>
          </p>
          {(hasYears || hasAgeRestriction) && (
            <div
              className={classNames(
                { "text-sm": small },
                "font-medium flex px-2 py-0.5 pl-1 text-white bg-primary rounded-md"
              )}
            >
              <UserIcon className="w-4 mr-1" />
              {hasAgeRestriction && ageConditionToText(ageRestriction[0])}
              {hasYears &&
                !hasAgeRestriction &&
                eventData?.name.text
                  .match(/\(.*\)/)[0]
                  .replace("(", "")
                  .replace(")", "")}
            </div>
          )}
        </div>
        {!noLink && (
          <div className="absolute top-2 right-2 border border-white rounded-full z-10 p-1">
            <ArrowTopRightOnSquareIcon className="h-5 w-5 text-white" />
          </div>
        )}
        {eventData.bookingDetails?.images?.[0] ? (
          <AccessibleImage
            img={eventData.bookingDetails?.images?.[0]}
            className={`${small ? "h-40" : "h-60"} object-cover w-full ${
              flatBottom ? "rounded-t-lg" : "rounded-lg"
            }`}
            imgSize="medium"
            hoverable
          />
        ) : (
          <div
            className={`${gradients[i % gradients.length]} ${
              small ? "h-40" : "h-60"
            } opacity-70 ${flatBottom ? "rounded-t-lg" : "rounded-lg"}`}
          />
        )}

        <div className="flex flex-col space-y-0 max-w-full absolute bottom-0 p-2">
          {showOrgChip && (
            <div className="w-full text-xs rounded-full border flex items-center border-gray-200  text-gray-700 space-x-1 bg-gray-50 hover:text-blue-700 hover:bg-blue-50 hover:border hover:border-blue-700 px-2 py-1">
              <BuildingLibraryIcon className="h-4 w-4" />{" "}
              <div className="flex-1 overflow-ellipsis truncate">
                {eventData?.bookingDetails?.orgName}
              </div>
            </div>
          )}
          {!hideOrg && !showOrgChip && eventData?.venue?.name && (
            <div className="flex mt-1 border rounded-full bg-white border-primary flex-row items-center pr-3">
              <div className="flex flex-row items-center justify-center p-1 rounded-full bg-primary text-white ml-[-1px] mt-[-1px] mb-[-1px]">
                <MapPinIcon className="w-4 h-4" />
              </div>
              <span className="text-sm ml-1 font-medium capitalize whitespace-nowrap overflow-hidden overflow-ellipsis">{`${eventData?.venue?.name?.toLowerCase()}`}</span>
            </div>
          )}
        </div>
      </div>

      <div
        className={`flex flex-col h-full justify-between p-2 leading-snug ${textClassName}`}
      >
        <div className="flex flex-col">
          <h3
            className={`${
              small ? "text-sm" : "text-base font-medium mt-1"
            } text-gray-800 font-bold`}
          >
            {hasYears
              ? eventData?.name.text.replace(/\(.*\)/, "")
              : eventData?.name.text}
          </h3>
          <p
            className={`${
              small ? "text-sm" : "text-sm"
            } font-light text-primary my-1`}
          >
            {eventData && recurrenceString(eventData).length > 0
              ? `${recurrenceString(eventData)}, `
              : ""}
            {timeString(eventData)}
          </p>
        </div>
        {!hideOrg && !showOrgChip && (
          <div className="flex">
            <div className="text-xs text-gray-400">
              By{" "}
              <span className="text-primary">{` ${eventData?.bookingDetails?.orgName}`}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  if (embed) {
    return (
      <div
        className={`rounded-xl shadow-sm h-full overflow-hidden ${hoverClass}`}
      >
        <a
          href={`/e/${eventData._id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content}
        </a>
      </div>
    );
  }
  if (noLink) {
    return (
      <button
        type="button"
        onClick={handleClick}
        className={`rounded-xl overflow-hidden text-left h-full w-full grid shadow-sm ${hoverClass}`}
      >
        {content}
      </button>
    );
  } else
    return (
      <Link
        href={`/e/${eventData._id}`}
        className={`rounded-xl shadow-sm overflow-hidden ${hoverClass}`}
      >
        {content}
      </Link>
    );
};

EventCard.propTypes = {
  eventData: PropTypes.object.isRequired,
  hoverClass: PropTypes.string,
  textClassName: PropTypes.string,
  hideOrg: PropTypes.bool,
  flatBottom: PropTypes.bool,
  small: PropTypes.bool,
  noLink: PropTypes.bool,
  showOrgChip: PropTypes.bool,
  handleClick: PropTypes.func,
};

EventCard.defaultProps = {
  handleClick: undefined,
  hoverClass: undefined,
  textClassName: undefined,
  hideOrg: false,
  flatBottom: false,
  small: false,
  noLink: false,
  showOrgChip: false,
};

export default EventCard;
