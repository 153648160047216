import { BUILD_LEVEL } from "../fire";

export const SMALL = "small";
export const MEDIUM = "medium";
export const LARGE = "large";

const SIZES = {
  [SMALL]: "200x200",
  [MEDIUM]: "500x500",
  [LARGE]: "1500x1500",
};

const addSizeSuffix = (fileName, size, useWebp) => {
  if (size) {
    let newFileName = fileName;
    const extensionPosition = fileName?.lastIndexOf(".");
    const slashPosition = fileName?.lastIndexOf("/");
    if (extensionPosition === -1) {
      return newFileName;
    } else {
      let extension = fileName?.slice(extensionPosition + 1);
      const fileNameWithoutExtension = fileName?.slice(
        slashPosition,
        extensionPosition
      );
      const filePath = fileName?.slice(0, slashPosition);
      const sizeSuffix = `_${SIZES[size]}`;
      if (useWebp) {
        extension = "webp";
      }
      newFileName = `${filePath}/sizes${fileNameWithoutExtension}${sizeSuffix}.${extension}`;
    }
    return newFileName;
  } else {
    return fileName;
  }
};

const goToCDN = (imageLink, size, useWebp) => {
  const storageLocation =
    BUILD_LEVEL === "production"
      ? "timetospare-public/o/"
      : "timetospare-staging.appspot.com/o/";
  if (
    !imageLink?.includes("firebasestorage.googleapis.com") ||
    !imageLink?.includes(storageLocation)
  ) {
    return imageLink;
  } else if (BUILD_LEVEL === "staging") {
    return imageLink;
  } else {
    const fileName = imageLink
      ?.split(storageLocation)?.[1]
      .split("?")?.[0]
      ?.replace(/%2F/g, "/");

    return `https://cdn.timetospare.com/${addSizeSuffix(
      fileName,
      size,
      useWebp
    )}`;
  }
};

export default goToCDN;
