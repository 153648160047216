import { rrulestr } from "rrule";

const shortToLong = {
  MO: "Mon",
  TU: "Tue",
  WE: "Wed",
  TH: "Thu",
  FR: "Fri",
  SA: "Sat",
  SU: "Sun",
};

const recurrenceString = (event) => {
  if (event?.recurrence && event?.recurrence?.RRULE) {
    try {
      const eventRRULE = rrulestr(event.recurrence.RRULE, { forceset: true });
      if (eventRRULE) {
        const rruleArray = eventRRULE.valueOf();
        const frequency = rruleArray[1];
        let freqString = "";
        if (frequency.includes("WEEKLY")) {
          if (frequency.includes("INTERVAL=2")) {
            freqString = `Alternate ${eventRRULE
              .dtstart()
              .toLocaleString("en-GB", { weekday: "long" })}s`;
          } else {
            freqString = `Every ${eventRRULE
              .dtstart()
              .toLocaleString("en-GB", { weekday: "long" })}`;
          }
        } else if (frequency.includes("MONTHLY")) {
          return "Every Month";
        } else if (frequency.includes("BYDAY")) {
          const freqSubstring = frequency
            .substring(frequency.search("BYDAY") + 6)
          const nextSegment = freqSubstring.indexOf(';');

          const days = freqSubstring
            .substring(0, nextSegment > -1 ? nextSegment : undefined)
            ?.split(",");

          if (
            days.length === 5 &&
            days.every((day) => ["MO", "TU", "WE", "TH", "FR"]?.includes(day))
          ) {
            freqString = "Every Weekday";
          } else if (
            days.length === 2 &&
            days.every((day) => ["SA", "SU"]?.includes(day))
          ) {
            freqString = "Every Weekend";
          } else {
            freqString = `${days.map((day) => shortToLong[day]).join(", ")}`;
          }
        } else if (frequency.includes("DAILY")) {
          freqString = "Every Day";
        }
        return freqString;
      }
    } catch (err) {
      return event.recurrence.type;
    }
  } else if (event?.recurrence?.dates) {
    // dates are in format YYYY-MM-DD, this doesn't work on Safari
    const dates = event.recurrence.dates.map((date) => {
      const dateArray = date.split("-");
      return new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
    });
    // only show first 2 and then have a "and x more" if there are more than 2
    if (dates.length > 2) {
      return `${dates
        .slice(0, 2)
        .map((date) =>
          date.toLocaleDateString("en-GB", {
            weekday: "short",
            day: "numeric",
            month: "short",
          })
        )
        .join(", ")} and ${dates.length - 2} more`;
    } else {
      return dates
        .map((date) =>
          date.toLocaleDateString("en-GB", {
            weekday: "short",
            day: "numeric",
            month: "short",
          })
        )
        .join(" & ");
    }
  } else if (event.start) {
    const startDate = event.start?.toDate
      ? event.start.toDate()
      : new Date(event.start);
    return `${startDate?.toLocaleString("en-GB", {
      weekday: "short",
      day: "numeric",
      month: "short",
    })}`;
  } else {
    return null;
  }
};

export default recurrenceString;
